<template>
  <div :class="{ contain: true, mobile: isMobile }">
      <div class="setting">
          <div class="setTitle">{{ $t("notification_settings") }}</div>
          <div class="description mt-lg-5">{{ $t("setting_des") }} <span>{{address}}</span></div>
          <div class="list mt-7">
                <div class="settingList" v-for="(item, i) in settingList" :key="i">
              <div class="settingItem d-flex flex-row" >
                    <v-checkbox v-model="item.isCheck" label="" class="checkbox"></v-checkbox>
                    <div class="clickItem d-flex flex-column mt-lg-3 ml-lg-2">
                        <div class="value">{{item.value}}</div>
                        <div class="valueDes">{{item.valueDes}}</div>
                    </div>
              </div>
          </div>
          </div>
          <div class="footer box">
        <button class="sub mb-10" submit @click="subCommit">
          {{ $t("myInfoFormSave") }}
        </button>
      </div>
      </div>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>

export default {
  components: { },
  data: function () {
    return {
        address:'',
        selectSettingList:[],
        isShowTip:[],
        settingList:[
            {
                key:1,
                value: this.$t('Set_Operation_result'),
                valueDes:this.$t('Set_Operation_result_des'),
                isCheck:true
            },
             {
                key:2,
                value: this.$t('Set_Sales_result'),
                valueDes:this.$t('Set_Sales_result_des'),
                isCheck:true
            },
            {
                key:3,
                value: this.$t('Set_Purchase_result'),
                valueDes:this.$t('Set_Purchase_result_des'),
                isCheck:true
            },
             {
                key:4,
                value: this.$t('Set_Bid_changes'),
                valueDes:this.$t('Set_Bid_changes_des'),
                isCheck:true
            },

            {
                key:7,
               value: this.$t('Set_New_followers'),
                valueDes:this.$t('Set_New_followers_des'),
                isCheck:true
            },
        
        ]
     
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
      this.address = this.$store.state.did.substr(0, 6) + "..." + this.$store.state.did.substr(-6);
      this.initDate();
      window.eventBus.$on("LangChange", this.onLangChange);
  },
 
  methods: {
      subCommit(){
          let timestamp = new Date().valueOf();
          this.settingList.forEach((v)=>{
              v.timestamp = timestamp
          })
             
          this.selectSettingList = this.settingList.filter(v=>v.isCheck && v.key != 0)
          this.isShowTip = this.settingList.filter(v=>v.key == 1)
          localStorage.setItem("EVM_isShowTip_"+this.$store.state.did,this.isShowTip[0].isCheck)
          localStorage.setItem('EVM_SettingSelectList_'+this.$store.state.did,JSON.stringify(this.selectSettingList))
            localStorage.setItem('EVM_SettingList_'+this.$store.state.did,JSON.stringify(this.settingList))
            this.$toast("success", this.$t("save_success"));

      },
       initDate(){
  

      this.settingList = [
            {
                key:1,
                value: this.$t('Set_Operation_result'),
                valueDes:this.$t('Set_Operation_result_des'),
                isCheck:true
            },
             {
                key:2,
                value: this.$t('Set_Sales_result'),
                valueDes:this.$t('Set_Sales_result_des'),
                isCheck:true
            },
            {
                key:3,
                value: this.$t('Set_Purchase_result'),
                valueDes:this.$t('Set_Purchase_result_des'),
                isCheck:true
            },
             {
                key:4,
                value: this.$t('Set_Bid_changes'),
                valueDes:this.$t('Set_Bid_changes_des'),
                isCheck:true
            },

            {
                key:7,
               value: this.$t('Set_New_followers'),
                valueDes:this.$t('Set_New_followers_des'),
                isCheck:true
            },

        
        ] 
          let StorageSettingList = JSON.parse(localStorage.getItem('EVM_SettingList_'+this.$store.state.did)) 
      if(StorageSettingList){
          for (let i = 0; i < StorageSettingList.length; i++) {
             this.settingList[i].isCheck = StorageSettingList[i].isCheck
              
          }
             localStorage.setItem('EVM_SettingList_'+this.$store.state.did,JSON.stringify(this.settingList))

   
      }else{
          this.settingList.forEach(e => {
              e.isCheck = true
             
         });
      }
     
    
  },
   onLangChange(){
       this.initDate()
      }
  
   
  },
 
};
</script>

<style lang="scss" scoped>
.contain {
     margin: 0;
  padding: 0;
   .setting{
       margin: 40px 230px;
       .setTitle{
            font-family: Helvetica;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       }
        .description{
            font-family: Helvetica;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        .list{
        .settingList{
            .settingItem{
            width: 750px;
            height: 73px;
            background-color: #ffffff;
            border: solid 2px #e3e3e3;
            margin-top:-2px;
            .checkbox{
                margin-left: 30px;
                
            }
            .clickItem{
                .value{
                font-family: Helvetica;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #270645;
                }
                .valueDes{
                    font-family: Helvetica;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #766983;
                }
                

            }
            }
            
        }
        }

  .footer {
    margin: 30px 155px 80px;
    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
      background-color: #1d42ff;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
    }
  }
       
   }
   
   
   
   
 &.mobile{
    .setting{
    margin: 40px 10px;
     .list{
        .settingList{
            .settingItem{
                width: 100%;
                .clickItem{
                .value{ 
                    font-size: 15px;
              
                }
                .valueDes{
                    font-size: 13px;
                    
                }
                

            }
            }
        }
    }
    .footer {
        margin: 30px 26px 80px;
        .sub{
            width: 280px;
        }
    }
    }

    }
  
}
</style>
